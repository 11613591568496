import videojs from 'video.js'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'

export default function setupVideos() {
  document.querySelectorAll('video-js').forEach((videoElem) => {
    const url = videoElem.dataset.url
    delete videoElem.dataset.url
    const video = videojs(videoElem, { html5: { vhs: { overrideNative: true } } });
    video.src({
      src: url,
      type: 'application/x-mpegurl',
    })
    video.hlsQualitySelector({ displayCurrentQuality: true });
  })
}
