import flatpickr from 'flatpickr'
import { Polish } from 'flatpickr/dist/l10n/pl'

export default function setupDatePickers() {
  flatpickr('.datepicker', {allowInput: true, locale: Polish})
  flatpickr('.datetimepicker', {allowInput: true, locale: Polish, enableTime: true, time_24hr: true})
  flatpickr('.timepicker',
    {allowInput: true, locale: Polish, enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true})
  flatpickr('.datetimerangepicker', {allowInput: true, locale: Polish, mode: 'multiple'})
}
