export default function() {
  document.querySelectorAll('.textarea-auto-resize').forEach((textarea) => {
    textarea.focus();
    const styles = window.getComputedStyle(textarea)
    const lht = parseInt(styles.getPropertyValue('line-height'), 10)
    const paddings = parseInt(styles.getPropertyValue('padding-top'), 10) + parseInt(styles.getPropertyValue('padding-top'), 10)
    textarea.addEventListener('input', () => {
      const lines = textarea.value.split(/\r|\r\n|\n/).length
      textarea.style.height = Math.min(lines, 3) * lht + paddings + 'px'
    })

    textarea.addEventListener('keypress', (e) => {
      if(e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        const form = textarea.closest('form');
        form.requestSubmit(form.querySelector('input[type=submit]'));
      }
    });
  })
}
