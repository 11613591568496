import axios from 'axios'
import './utils/analytics'
import PricingCalculator from './utils/pricing_calculator'
import ContentBuilder from './utils/content_builder'
import rebuildTooltips from './utils/rebuild_tooltips'
import setupDatePickers from './utils/setup_date_pickers'
import setupVideos from './utils/setup_videos'
import setupMsgTextarea from './utils/setup_msg_textarea'
import setupSelects from './utils/setup_selects'

const msgAudio = new Audio('/sounds/message.mp3');

document.addEventListener('DOMContentLoaded', () => {
  setupDatePickers()
  rebuildTooltips()
  setupVideos()
  setupMsgTextarea()
  setupSelects()

  document.querySelectorAll('.form-autoapply input, .form-autoapply select').forEach((el) => {
    el.addEventListener('change', () => { el.form.submit() })
  })

  document.querySelectorAll('#directUpload').forEach((form) => {
    form.addEventListener('submit', function (e) {
      e.preventDefault()

      const progress = form.querySelector("#progress");
      const uploadOptions = {
        method: 'PUT',
        data: e.target[1].files[0],
        url: form.getAttribute('action'),
        onUploadProgress: function (data) {
          const perc = Math.round(data.progress * 100);
          progress.innerHTML = `<div class="row g-2 align-items-center">
                            <div class="col-auto">
                              ${perc}%
                            </div>
                            <div class="col">
                              <div class="progress">
                                <div class="progress-bar" style="width: ${perc}%"></div>
                              </div>
                            </div>
                          </div>`
        },
      }
      axios(uploadOptions).then(() => {
        alert('Plik zapisany!')
        window.location = '/admin/content/videos'
      }, () => {
        alert('error')
      })
    })
  })

  document.querySelectorAll('.content-builder').forEach((contentBuilderElem) => {
    new ContentBuilder(contentBuilderElem)
  })

  document.querySelectorAll('#formFileUpload').forEach((fileUpload) => {
    const button = fileUpload.querySelector('.btn')
    const field = fileUpload.querySelector('input[type=file]')
    button.addEventListener('click', () => { field.click() })
    field.addEventListener('change', () => { field.form.submit() })
  })

  document.querySelectorAll('.chat').forEach((chat) => {
    const csrfToken = document.querySelector('[name=\'csrf-token\']').content

    const scrollAndRead = () => {
      chat.scrollTo(0, chat.scrollHeight)
      fetch(`${location.pathname}/read`, {method: 'POST', headers: {'X-CSRF-Token': csrfToken}}).
        then(async (response) => {
          window.Turbo.renderStreamMessage(await response.text())
        })
    }

    scrollAndRead()
    chat.addEventListener('DOMSubtreeModified', scrollAndRead)
  })

  document.querySelectorAll('#new_msg_wrap').forEach((input) => {
    input.addEventListener('DOMSubtreeModified', () => {
      setupMsgTextarea();
    })
  })

  document.querySelectorAll('#turbo_stream_receiver').forEach((elem) => {
    elem.addEventListener('message', () => {
      msgAudio.play();
    })
  })

  document.querySelectorAll('#additional_catch_ups_form').forEach((form) => {
    form.querySelectorAll('input').forEach((el) => {
      el.addEventListener('change', () => {
        fetch(`/client/catch_ups/estimate`, { method: 'POST', headers: {'Accept': 'text/vnd.turbo-stream.html'}, body: new FormData(form) }).
          then(async (response) => {
            window.Turbo.renderStreamMessage(await response.text())
          })
      })
    })
  })
})

document.addEventListener('turbo:frame-load', () => {
  document.querySelectorAll('.pricing-calculator').forEach((elem) => {
    return new PricingCalculator(elem)
  })

  rebuildTooltips();

  document.querySelectorAll('*[data-close-modal]').forEach((elem) => {
    elem.addEventListener('click', () => {
      document.querySelector('turbo-frame[id=modal]').innerHTML = ''
    })
  })
})
