import Sortable from 'sortablejs'
import setupSelects from './setup_selects'

export default class {
  node = null
  parts = null

  constructor (node) {
    this.node = node
    this.parts = node.querySelector('div[data-target="parts"]')
    this.bound_destroyHandle = this.destroyHandle.bind(this);
    this.mountAddButtons()
    this.mountDestroyButtons()
    this.setupSortable();
    this.fixPositions()
  }

  mountAddButtons () {
    this.node.querySelectorAll('div[data-action="add-part"]').forEach((button) => {
      button.addEventListener('click', () => {
        const data = button.dataset.form.replace(/REPLACEID/g, Date.now())
        this.parts.insertAdjacentHTML('beforeend', data)
        this.mountDestroyButtons()
        this.fixPositions()
        setupSelects()
      })
    })
  }

  mountDestroyButtons () {
    this.node.querySelectorAll('div[data-action="destroy"]').forEach((button) => {
      button.removeEventListener('click', this.bound_destroyHandle)
      button.addEventListener('click', this.bound_destroyHandle)
    })
  }

  destroyHandle (event) {
    if (confirm('Jesteś pewny?')) {
      const parent = event.target.closest('.accordion-item')
      parent.querySelector('.destroy-input').value = 'true'
      parent.classList.add('d-none')
      this.fixPositions()
    }
  }

  fixPositions () {
    this.node.querySelectorAll('.accordion-item:not(.d-none) .position-field').
      forEach((elem, index) => {
        elem.value = index + 1
      })
  }

  setupSortable() {
    this.sortable = new Sortable(this.parts, {onSort: this.fixPositions.bind(this)})
  }
}
