export default class {
  node = null
  fixedPrice = null
  price = null
  duration = 30

  constructor (node) {
    this.node = node
    this.fixedPrice = parseFloat(node.dataset['fixedprice'])
    this.price = parseFloat(node.dataset['price'])

    node.querySelectorAll('.form-selectgroup-input').forEach((elem) => {
      elem.addEventListener('change', (event) => {
        this.duration = parseInt(event.target.value)
        this.calculate()
      })
    })
    this.calculate()
  }

  calculate () {
    let singleElems = this.node.querySelectorAll('.pricing-calculator__single-price')
    let singlePrice = (this.price * this.duration / 60.0)
    singleElems.forEach((elem) => elem.innerHTML = singlePrice.toFixed(2) + ' zł')

    let fixedPrice = (this.fixedPrice * this.duration / 60.0)
    this.node.querySelector('.pricing-calculator__fixed-price').innerHTML = fixedPrice.toFixed(2) + ' zł'

    let total = singlePrice * 4 + fixedPrice
    this.node.querySelector('.pricing-calculator__monthly-price').innerHTML = total.toFixed(2) + ' zł *'
  }
}
