import { Popover, Tooltip } from 'bootstrap'

export default function rebuildTooltips() {
  let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  popoverTriggerList.map(function (popoverTriggerEl) {
    let options = {
      delay: {show: 50, hide: 50},
      html: popoverTriggerEl.getAttribute('data-bs-html') === "true" ?? false,
      placement: popoverTriggerEl.getAttribute('data-bs-placement') ?? 'auto'
    };
    return new Popover(popoverTriggerEl, options);
  });

  let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    let options = {
      delay: {show: 50, hide: 50},
      html: tooltipTriggerEl.getAttribute("data-bs-html") === "true" ?? false,
      placement: tooltipTriggerEl.getAttribute('data-bs-placement') ?? 'auto',
    };
    return new Tooltip(tooltipTriggerEl, options);
  });
}
